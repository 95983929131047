import { Controller } from "stimulus"
import Cookies from "js-cookie"
import { last } from 'underscore'

export default class extends Controller {
  connect() {
    this.VALUE = 'y'
    this.OPEN_CSS_CLASS = 'announcement-bar--open'
    this.OPEN_CSS_CLASS_FOR_BODY = 'body--announcement-bar'
    this.key = `close-announcement--${this.element.dataset['announcementId']}`
    this.applyOverrides()
  }

  applyOverrides() {
    const dataset = this.element.dataset
    const bgImageUrl = dataset['backgroundImage']
    const bgColor = dataset['fallbackBackgroundColor']
    const textColor = dataset['textColor']
    const linkColor = dataset['linkColor']

    if(bgImageUrl) {
      this.element.style.backgroundImage = `url('${bgImageUrl}')`
      this.element.style.backgroundSize = "inherit"
    }
    if(bgColor) {
      this.element.style.backgroundColor = bgColor
    }
    if(textColor) {
      this.element.style.color = textColor
    }
    if(linkColor) {
      const links = this.element.querySelectorAll('a')
      links.forEach((el) => {
        el.style.color = linkColor
      })
    }
  }

  close() {
    this.element.classList.remove(this.OPEN_CSS_CLASS)
    document.body.classList.remove(this.OPEN_CSS_CLASS_FOR_BODY)

    var params = [this.key, this.VALUE, {
      domain: `.${this.domain()}`, path: '/', expires: 365
    }]

    Cookies.set(...params)
  }

  domain() {
    return last(document.location.hostname.split('.'), 2).join('.')
  }
}
