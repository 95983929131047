import { Controller } from "stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  connect() {
    // This `CLOSED_DOMAIN` value could be used for closing popup by subdomain in future
    this.CLOSED_DOMAIN = "_all";
    this.OPEN_CSS_CLASS = "cta-toast--open";
    this.key = "close-cta-toast-20240731";
    if(this.previouslyClosedByUser()) {
      this.closeOnce()
    } else {
      this.element.classList.add(this.OPEN_CSS_CLASS)
      this.addBackgroundColor()
    }
  }

  closeOnce() {
    this.element.classList.remove(this.OPEN_CSS_CLASS)
  }

  previouslyClosedByUser() {
    return !!(Cookies.get(this.key) == this.CLOSED_DOMAIN)
  }

  close() {
    const domain = this.element.dataset["domain"]
    const params = [this.key, this.CLOSED_DOMAIN, {
      domain: `.${domain}`, path: '/', expires: 365
    }]

    Cookies.set(...params)
    this.closeOnce()
  }

  addBackgroundColor() {
    const backgroundColor = this.element.dataset.backgroundColor
    if (backgroundColor) {
      const wrapper = this.element.querySelector(".tuts-toast-banner__wrapper")
      if (wrapper) {
        wrapper.style.backgroundColor = backgroundColor
        wrapper.style.borderColor = backgroundColor
      }
    }
  }
}
