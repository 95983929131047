import {Controller} from "stimulus"

export default class UtmController extends Controller {
  static values = {
    utmSource: { type: String, default: "tutsplus" },
    utmMedium: { type: String, default: "" },
    utmCampaign: { type: String, default: "" },
    languageCode: { type: String },
    isContentPage: { type: Boolean, default: false }
  }

  initialize() {
    this.initBinding()
    // Comment the line below to enable this feature for all subdomains
    if (!this.isDesignSubdomain()) { return }

    this.initUtmValue()
    this.addUtmParamsToEnvatoLinks()
  }

  initBinding() {
    this.urlObject = new URL(window.location.href)
    this.searchParams = this.urlObject.searchParams
    this.pathname = this.urlObject.pathname
    this.hostname = this.urlObject.hostname
    this.host = this.urlObject.host
  }

  initUtmValue() {
    if (!this.isContentPageValue) {
      this.utmMediumValue = this.getUtmMediumFromUrl()
      this.utmCampaignValue = this.getUtmCampaignFromUrl()
    }
    if (this.isTranslation()) {
      this.utmCampaignValue = `${this.languageCodeValue}/${this.utmCampaignValue}`
    } 
  }

  addUtmParamsToEnvatoLinks() {
    let linkElements = this.element.querySelectorAll('a')
    linkElements.forEach((linkElement) => {
      if (linkElement.href.length > 0) {
        let linkUrlObject = new URL(linkElement.href)
        if (this.isExtenalEnvatoHost(linkUrlObject) && !this.isIncludeUtmParams(linkUrlObject)) {
          this.addUtmParamsToLink(linkElement)
        }
      }
    })
  }

  isDesignSubdomain() {
    return this.host.startsWith('design')
  }

  isExtenalEnvatoHost(linkUrlObject) {
    let urlHost = linkUrlObject.host
    if (urlHost.length < 1) { return false }

    let result = false
    for (let envatoHost of this.envatoHosts()) {
      if (urlHost.includes(envatoHost)) {
        result = true
        break
      }
    }
    return result
  }

  isIncludeUtmParams(linkUrlObject) {
    if (linkUrlObject.search.length < 1) { return false }

    return linkUrlObject.search.includes('utm_')
  }

  isTranslation() {
    return this.languageCodeValue.length > 1 && this.languageCodeValue !== 'en'
  }

  addUtmParamsToLink(linkElement) {
    let linkUrlObject = new URL(linkElement.href)
    linkUrlObject.searchParams.append('utm_campaign', this.utmCampaignValue)
    linkUrlObject.searchParams.append('utm_medium', this.utmMediumValue)
    linkUrlObject.searchParams.append('utm_source', this.utmSourceValue)
    linkUrlObject.searchParams.append('utm_debug', this.debugUtmParams(linkElement))
    linkElement.href = linkUrlObject.href
  }

  debugUtmParams(linkElement) {
    return `${window.location.href}---${linkElement.className}---${linkElement.innerText}---${this.utmMediumValue}---${this.utmCampaignValue}`
  }

  getUtmMediumFromUrl() {
    if (this.isSearchPage()) { return 'search' }
    if (this.isHomePage() ) { return "homepage" }
    if (this.isListingPage()) { return 'listing' }
    return 'misc'
  }

  getUtmCampaignFromUrl() {
    if (this.isSearchPage()) { return this.pathname.split('/search/')[1] }
    if (this.isHomePage()) { return this.hostname }
    if (this.isListingPage()) { return `${this.hostname}${this.pathname}` }
    return this.pathname
  }

  envatoHosts() {
    return ['envato', 'market', 'elements', 'themeforest', 'placeit', 'mixkit', 'codecanyon', 'audiojungle', 'videohive', 'graphicriver', 'photodune', '3docean', 'reshot']
  }

  utmParamsNames() {
    return ['utm_campaign', 'utm_medium', 'utm_source']
  }

  isListingPage() {
    return !!this.element.querySelector('[name="content-filters__content-section"]')
  }
  isSearchPage() {
    return this.pathname.split('/').includes('search')
  }
  isHomePage() {
    return this.pathname === '/' && this.searchParams.size === 0
  }
}
