import {Controller} from "stimulus"

export default class extends Controller {

  connect() {
    this.loadGoogleAnalytics()

    window.postGADataLayerEvents = (event, eventCategory, readableLinkText) => {
      const link = event.currentTarget
      const params = event.params
      const url = new URL(link.href || link.action)
      const linkText = readableLinkText || link.text

      if (url.href === 'javascript:') {
        return;
      }

      const toSnakeCase = str => str.trim().toLowerCase().replace(/\s+/g, "_")
      const safeLinkText = params.url ? toSnakeCase([params.url, linkText].join(';')) : toSnakeCase(linkText)
      url.searchParams.delete('_ga')

      const ga3 = {
        'event': event.type,
        'eventCategory': eventCategory,
        'eventAction': `${event.type}ed - ${url.protocol + '//' + url.host + url.pathname + url.hash}`,
        'eventLabel': safeLinkText,
      }
      dataLayer.push(ga3)

      const ga4 = {
        'event': 'user_event',
        'event_name': event.type,
        'link_domain': url.hostname,
        'context': eventCategory,
        'link_text': toSnakeCase(linkText),
        'link_url': url.protocol + '//' + url.host + url.pathname + url.hash,
        'link_query': url.searchParams.toString(),
        'outbound': false,
      }
      dataLayer.push(ga4)
    }

    window.sendExternalClickEvent = (event, eventCategory) => {
      let url;
      let eventLabel;
      if (eventCategory == 'email_signup'){
        url = window.location.href
        eventLabel = $('.email-setting-tease').text()
      } else {
        const link = event.currentTarget
        const linkText = link.text.trim().length === 0 ? 'no link text' : link.text
        const clickedFrom = link.dataset.value

        url = new URL(link.href || link.action)
        url.searchParams.delete('_ga')

        const className = getClassName()
        eventLabel = createEventLabelWithCustomValue()
          || createEventLabelWithImage()
          || createEventLabelWithButton()
          || createEventLabelWithClassName()
          || linkText

        function createEventLabelWithCustomValue() {
          if (clickedFrom) {
            return `${clickedFrom}; ${linkText}`
          }
          return null
        }

        function createEventLabelWithImage() {
          const hasImage = link.children.length > 0 && link.children[0] instanceof Image
          return hasImage ? `image; ${link.children[0].alt}` : null;
        }

        function createEventLabelWithButton() {
          return className && className.includes('btn') ? `button; ${linkText}` : null;
        }

        function createEventLabelWithClassName() {
          return className ? `${className}; ${linkText}` : null;
        }

        function getClassName() {
          const linkClassName = link.className || link.firstChild.className
          return linkClassName ? (linkClassName.substring(0, linkClassName.indexOf('_'))
            || linkClassName.substring(0, linkClassName.indexOf(' '))
            || linkClassName) : null;
        }
      }

      const clickEvent = {
        'event': event.type,
        eventCategory,
        'eventAction': `Clicked link - ${url}`,
        eventLabel
      }
      dataLayer.push(clickEvent)
    }
  }

  sendElementsClickEvent(event) {
    sendExternalClickEvent(event, 'elements_click')
  }

  sendMarketClickEvent(event) {
    sendExternalClickEvent(event, 'market_click')
  }

  sendPlaceitClickEvent(event) {
    sendExternalClickEvent(event, 'placeit_click')
  }

  sendSignUpEmailClickEvent(event) {
    if($('.email-setting-email-input-field ')[0].checkValidity()) {sendExternalClickEvent(event, 'email_signup')}
  }

  loadGoogleAnalytics() {
    const dataset = this.element.dataset;
    const accountId = dataset['gaAccount'];
    const accountDomain = dataset['gaDomain'];
    const eventDataJson = document.querySelector('[name=google-analytics-data]')?.getAttribute('content');
    const eventData = eventDataJson ? JSON.parse(eventDataJson) : [];
    const userId = dataset['gaUserId'];
    const autoLinkDomains = dataset['gaAutolinkDomains'] ? dataset['gaAutolinkDomains'].split(",") : [];

    if (accountId && accountDomain) {
      ga('create', accountId, 'auto', {'userId': userId, 'allowLinker': true});
      ga('require', 'linker');
      ga('linker:autoLink', autoLinkDomains, false, true);
      ga('require', 'displayfeatures');     // enable demographics

      window.ga('send', 'pageview');

      for (let i in eventData) {
        window.ga.apply(this, eventData[i]);
      }
    }
  }
}
